<template>
  <v-app>
    <v-main>
      <div class="divMain">
        <v-container fluid tag="div" class="ctnForm">
          <form-login class="fmLogin" />
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'LoginPage',

  components: {
    FormLogin: () => import('../components/base/FormLogin.vue'),
  },
}
</script>

<style>
.divMain {
  height: 100%;
}
.ctnForm {
  width: 600px !important;
}

.fmLogin {
  padding-top: 40% !important;
  padding-bottom: 10%;
}
</style>
